.reset__page {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.reset__container {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #dcdcdc;
  padding: 30px;
}
.reset__textBox {
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
}
.reset__btn {
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
  border: none;
  color: white;
  background-color: rgb(128, 155, 230);
  cursor: pointer;
}
.reset__page div {
  margin-top: 7px;
}
