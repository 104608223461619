.download {
  margin: 10px;
}
.bottom_left {
  position: absolute;
  bottom: 0;
  left: 0;
  color: white;
  font-size: 25pt;
  display: flex;
}
.download:hover {
  opacity: 0.5;
}
.download:active {
  opacity: 0.2;
}
.hidden {
  visibility: hidden;
}
