html,
body,
#root,
#root > div {
  height: 100%;
}

.App {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.Header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: rgb(128, 155, 230);
  color: white;
  height: 50px;
  z-index: 10;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.7);
  user-select: none;
}

.TitleBox {
  display: flex;
  flex-basis: 70px;
  max-width: 33vw;
  font-size: 3em;
  font-weight: bolder;
  text-align: center;
  padding: 10px;
}

.counter {
  display: flex;
  flex-basis: 300px;
  max-width: 40vw;
  font-size: 2em;
  align-items: center;
  text-align: center;
}

.TextBox {
  display: flex;
  position: absolute;
  top: 0;
  left: 40%;
  height: 10vh;
  max-height: 50px;
  width: 20%;
  border-radius: 30px;
  font-size: max(0, 2.8vh);
  align-items: center;
  justify-content: center;
  color: white;
  z-index: 10;
}
.TextBox:hover {
  border: 2px solid white;
  background-color: rgba(89, 108, 158, 0.541);
}
.TextBox:active {
  border: 2px solid rgba(89, 108, 158, 0.541);
}

button.upload {
  height: 80%;
  flex-basis: 250px;
  max-width: 33vw;
  font-size: 1em;
  background-color: white;
  border: 2px solid white;
  border-radius: 5px;
  color: rgba(128, 155, 230);
  text-transform: uppercase;
  font-weight: bolder;
  cursor: pointer;
}
button.upload:hover {
  border: 2px solid rgba(128, 155, 230, 0.541);
}
button.upload:active {
  background-color: rgba(40, 48, 71, 0.226);
}

button.upload-disabled {
  height: 80%;
  flex-basis: 250px;
  max-width: 33vw;
  font-size: 1em;
  background-color: white;
  border: 2px solid white;
  border-radius: 5px;
  color: rgba(128, 155, 230);
  text-transform: uppercase;
  font-weight: bolder;
  cursor: not-allowed;
  opacity: 0.5;
}

.accession {
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 20vw;
  height: 15vh;
  font-size: 22px;
  background-color: rgba(128, 155, 230, 1);
  border: 2px solid rgb(141, 163, 224);
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: bolder;
  cursor: pointer;
}
.accession:hover {
  background-color: rgba(128, 155, 230, 0.8);
  border: 2px solid rgba(128, 155, 230, 0.5);
}
.accession:active {
  background-color: rgba(128, 155, 230, 0.5);
  border: 2px solid rgba(128, 155, 230, 0.3);
}

.Content {
  display: flex;
  flex: 1;
  flex-direction: column;
  color: white;
}

.Announcements {
  flex-basis: 16px;
  font-size: min(24pt, 2em);
  text-align: center;
  z-index: 2;
}

.progressbar {
  position: absolute;
  width: 100%;
  height: 12px;
  z-index: 3;
}

.PreviewContainer {
  display: flex;
  height: 89vh;
  max-height: 100%;
  max-width: 100%;
  user-select: none;
  justify-content: center;
}

.PreviewImg {
  max-height: 100%;
  width: auto;
  object-fit: contain;
}

.Overlay {
  cursor: pointer;
  position: absolute;
  z-index: 9;
  user-select: none;
}

.Overlay.drag {
  position: absolute;
  user-select: none;
}

.rightPanel {
  user-select: none;
  flex-direction: column;
  display: flex;
  justify-content: space-around;
  position: fixed;
  right: 2vw;
  bottom: 10vh;
  width: 5vw;
  height: 70vh;
  z-index: 10;
}

.level {
  display: flex;
  position: relative;
  z-index: 11;
  height: 5vh;
  justify-content: center;
  margin: 5px;
}

.buttonLevel {
  background-color: transparent;
  border: 2px solid rgb(128, 155, 230);
  color: #fff;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  z-index: 11;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  cursor: pointer;
}
.buttonLevelSelected {
  background-color: rgb(128, 155, 230);
  border: 2px solid rgb(128, 155, 230);
  color: black;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  z-index: 11;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  cursor: pointer;
  font-weight: bolder;
}
.buttonLevelSelected:hover {
  background-color: rgba(89, 108, 158, 0.75);
  border: 2px solid rgb(128, 155, 230);
}
.buttonLevelSelected:active {
  border: 2px solid rgba(128, 155, 230, 0.541);
  background-color: rgba(40, 48, 71, 0.226);
}
.buttonLevel:hover {
  background-color: rgb(89, 108, 158, 0.25);
  border: 2px solid rgb(128, 155, 230);
}
.buttonLevel:active {
  border: 2px solid rgba(128, 155, 230, 0.541);
  background-color: rgba(40, 48, 71, 0.226);
}

.point {
  position: absolute;
  width: 10px;
  height: 10px;
  z-index: 3;
  border: 2px solid red;
  cursor: crosshair;
  user-select: none;
}
.point-drag {
  position: absolute;
  width: 10px;
  height: 10px;
  z-index: 3;
  border: 2px solid red;
  cursor: none;
  user-select: none;
}
.pointInactive {
  position: absolute;
  width: 10px;
  height: 10px;
  z-index: 2;
  border: 2px solid blue;
}
.switch {
  position: fixed;
  bottom: 1vw;
  z-index: 11;
  left: 1vw;
  color: white;
}
.line {
  pointer-events: none;
  opacity: 0.5;
}

.reset {
  position: absolute;
  right: -10px;
  top: -10px;
  width: 20px;
  height: 20px;
  background-color: rgba(128, 155, 230, 0.753);
  color: #fff;
  border-radius: 50px;
  z-index: 12;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.reset:hover {
  background-color: rgba(226, 79, 79, 0.671);
}
.reset:active {
  background-color: red;
}
.click_icon:hover {
  color: rgba(89, 108, 158, 0.877);
}

.viewType {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.button-previous {
  position: absolute;
  left: 2vw;
  background-color: transparent;
  border: 2px solid rgb(128, 155, 230);
  color: white;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  z-index: 11;
  height: 5vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  cursor: pointer;
  margin: 5px;
  padding: 5px;
  user-select: none;
}
.button-next {
  position: absolute;
  right: 2vw;
  background-color: transparent;
  border: 2px solid rgb(128, 155, 230);
  color: white;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  z-index: 11;
  height: 5vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  cursor: pointer;
  margin: 5px;
  padding: 5px;
  user-select: none;
}
.button-next:hover {
  background-color: rgb(89, 108, 158, 0.25);
  border: 2px solid rgb(128, 155, 230);
}
.button-previous:hover {
  background-color: rgb(89, 108, 158, 0.25);
  border: 2px solid rgb(128, 155, 230);
}
.button-previous-disabled {
  position: absolute;
  left: 2vw;
  background-color: transparent;
  border: 2px solid rgb(128, 155, 230);
  color: white;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  z-index: 11;
  height: 5vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  margin: 5px;
  padding: 5px;
  opacity: 0.2;
  user-select: none;
  cursor: not-allowed;
}
.button-next-disabled {
  position: absolute;
  right: 2vw;
  background-color: transparent;
  border: 2px solid rgb(128, 155, 230);
  color: white;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  z-index: 11;
  height: 5vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  margin: 5px;
  padding: 5px;
  opacity: 0.2;
  cursor: not-allowed;
  user-select: none;
}
.placeholder {
  display: flex;
  width: 100vw;
  padding-top: 20vh;
  padding-bottom: 20vh;
  justify-content: center;
}
.save-text {
  position: absolute;
  bottom: 0;
  left: 0;
  color: rgb(128, 155, 230);
  margin-left: 10px;
}
